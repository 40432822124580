//获取用户信息
const getUserInfoByIdUrl = `/healthhubApi/userInfo/wx/getMiniUserInfoByOpenId`;
//完善信息
const updateUserInfoUrl = `/healthhubApi/userInfo/wx/updateMiniUserInfo`;
//获取用户检测列表
const getExamListUrl = `/healthhubApi/exam/getExamList`;
//用户信息详情
const getMiniUserlnfoByUserldUrl = `/healthhubApi/userInfo/wx/getMiniUserInfoByUserId`;

export {
  getUserInfoByIdUrl,
  getMiniUserlnfoByUserldUrl,
  getExamListUrl,
  updateUserInfoUrl,
};
