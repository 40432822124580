<template>
  <div class="healthHouse-myInfo">
    <div class="info">完善个人信息有助于更好地为您提供服务～</div>
    <div class="userinfo">
      <div class="item">
        <div class="item-l">头像</div>
        <div class="item-r headImg">
          <img
            class="avatar"
            v-if="form.avatarUrl"
            :src="form.avatarUrl"
            alt=""
            @click="changeImg"
          />
          <v-upload
            v-else
            :imgUrl.sync="form.avatarUrl1"
            :activityPicture.sync="form.avatarUrl"
            ref="load2"
            :maxCount="1"
          ></v-upload>
        </div>
      </div>
      <div class="item" @click="getFocus('realName')">
        <div class="item-l">姓名</div>
        <div class="item-r">
          <v-input ref="realName" v-model="form.realName"></v-input>
        </div>
      </div>
      <div class="item" @click="getFocus('nickName')">
        <div class="item-l">昵称</div>
        <div class="item-r">
          <v-input ref="nickName" v-model="form.nickName"></v-input>
        </div>
      </div>
      <div class="item" @click="getFocus('mobile')">
        <div class="item-l">手机号</div>
        <div class="item-r">
          <v-input
            ref="mobile"
            type="number"
            :maxlength="11"
            v-model="form.mobile"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">性别</div>
        <div class="item-r">
          <div class="sex">
            <div class="sex-l" @click="selectSex('M')">
              <div class="img">
                <img
                  v-if="form.gender == 'M'"
                  src="./img/sexSelected.png"
                  alt=""
                />
                <img v-else src="./img/sex.png" alt="" />
              </div>
              <div class="txt">男士</div>
            </div>
            <div class="sex-r" @click="selectSex('F')">
              <div class="img">
                <img
                  src="./img/sexSelected.png"
                  v-if="form.gender == 'F'"
                  alt=""
                />
                <img v-else src="./img/sex.png" alt="" />
              </div>
              <div class="txt">女士</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item height" @click="getFocus('height')">
        <div class="item-l">身高</div>
        <div class="item-r">
          <v-input ref="height" v-model="form.height"></v-input
          ><span class="danwei">cm</span>
        </div>
      </div>
      <div class="item" @click="getFocus('age')">
        <div class="item-l">出生日期</div>
        <div class="item-r">
          {{ form.yearOfBirth }}
        </div>
      </div>
      <div class="item" @click="getFocus('bpDeviceNo')">
        <div class="item-l">血压计设备号</div>
        <div class="item-r">
          <v-input ref="bpDeviceNo" v-model="form.bpDeviceNo"></v-input>
        </div>
      </div>
    </div>
    <v-dateTimePicker
      type="date"
      :value="form.yearOfBirth1"
      :isAuto="true"
      :minDate="new Date('1932-01-01')"
      :valueShow.sync="isShowDate"
      :max-date="maxDate"
      @confirmPicker="confirmNurseryDate"
    ></v-dateTimePicker>
    <div class="save" @click="save">提交保存</div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateUserInfoUrl, getMiniUserlnfoByUserldUrl } from "./api";
import moment from "moment";
export default {
  name: "healthHouse-myInfo",
  data() {
    return {
      isShowDate: false,
      form: {
        realName: "",
        nickName: "",
        mobile: "",
        height: "",
        gender: "",
        avatarUrl: "",
        avatarUrl1: "",
        yearOfBirth: "",
        yearOfBirth1: new Date("1980-01-01"),
        bpDeviceNo: "",
      },
      maxDate: new Date(),
      type: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getUser();
  },
  methods: {
    confirmNurseryDate(data) {
      if (data) {
        this.form.yearOfBirth = moment(data).format("YYYYMM");
      }
    },
    changeImg() {
      this.form.avatarUrl = "";
      this.form.avatarUrl1 = "";
    },
    async getUser() {
      let params = {
        id: this.userInfo.id,
      };
      let res = await this.$axios.get(`${getMiniUserlnfoByUserldUrl}`, {
        params,
      });
      if (res.code == 200) {
        let obj = res.data;
        for (var i in this.form) {
          if (obj[i]) {
            this.form[i] = obj[i];
          }
        }
        if (!this.form.avatarUrl) {
          this.form.avatarUrl = this.userInfo.avatarUrl;
        }
        this.form.avatarUrl1 = this.userInfo.avatarUrl;
        if (!this.form.nickName) {
          this.form.nickName = this.userInfo.nickName;
        }
        if (!this.form.gender) {
          this.form.gender = this.userInfo.gender;
        }
        if (this.form.yearOfBirth) {
          let year = String(this.form.yearOfBirth).substring(0, 4);
          let month = String(this.form.yearOfBirth).substring(4, 6);
          let date = year + "-" + month + "-" + "01";
          this.form.yearOfBirth1 = new Date(date);
        }
      }
    },
    async save() {
      if (!this.form.avatarUrl) {
        this.$toast({ message: "请上传头像", duration: 2000 });
        return;
      }
      if (!this.form.realName) {
        this.$toast({ message: "请输入姓名", duration: 2000 });
        return;
      }
      if (!this.form.nickName) {
        this.$toast({ message: "请输入昵称", duration: 2000 });
        return;
      }
      if (!this.form.mobile) {
        this.$toast({ message: "请输入手机号", duration: 2000 });
        return;
      }
      if (!this.form.gender) {
        this.$toast({ message: "请选择性别", duration: 2000 });
        return;
      }
      if (!this.form.height) {
        this.$toast({ message: "请输入身高", duration: 2000 });
        return;
      }
      if (!this.form.yearOfBirth) {
        this.$toast({ message: "请选择出生日期", duration: 2000 });
        return;
      }
      let user = this.userInfo;
      let newUser = {};
      let url = updateUserInfoUrl;
      Object.assign(newUser, user, this.form);
      console.log(8888, user);
      let res = await this.$axios.post(`${url}`, newUser);
      if (res.code == 200) {
        this.$toast({ message: "保存信息成功", duration: 2000 });
        if (this.type) {
          this.$router.push({
            name: "healthHouse-my",
          });
        } else {
          this.$router.go(-1);
        }
        return;
      }
    },
    selectSex(data) {
      if (data == "M") {
        this.form.gender = "M";
      } else if (data == "F") {
        this.form.gender = "F";
      } else {
        this.form.gender = "N";
      }
    },
    getFocus(data) {
      if (data == "age") {
        this.isShowDate = true;
      } else {
        this.$refs[data].$refs.field.focus();
      }
    },
  },
};
</script>

<style scoped lang="less">
.healthHouse-myInfo {
  height: 100%;
  background: rgba(249, 249, 249, 1);
  box-sizing: border-box;
  position: relative;
  .info {
    font-size: 26px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 36px;
    padding: 28px 0 28px 28px;
  }
  .userinfo {
    padding: 0 30px;
    background: #fff;
    .item {
      padding: 30px 0;
      border-bottom: 2px solid #f5f5f5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .item-l {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
      }
      .item-r {
        font-size: 28px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 40px;
        .avatar {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        /deep/ .v-upload {
          width: 100px;
          height: 100px;
          .van-uploader {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            .van-uploader__wrapper {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              .van-uploader__upload {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
              .van-uploader__preview {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                margin: 0;
                border: 0;
              }
              .van-uploader__preview-image {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                margin: 0;
              }
              .van-uploader__mask {
                display: none;
              }
            }
          }
        }
        .sex {
          display: flex;
          align-items: center;
          .sex-l,
          .sex-r {
            display: flex;
            align-items: center;
            margin-left: 36px;
            .img {
              margin-right: 10px;
              font-size: 0;
              width: 22px;
              height: 22px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .txt {
              font-size: 28px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.85);
              line-height: 40px;
            }
          }
        }
        /deep/ .van-cell {
          height: 40px !important;
          padding: 0;
          input {
            text-align: right;
          }
        }
        .danwei {
          font-size: 28px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
          line-height: 40px;
          height: 40px;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      .headImg {
        width: 100px;
        height: 100px;
        font-size: 0;
      }
    }
    .height {
      .item-r {
        /deep/ .van-cell {
          input {
            padding: 0 50px 0 0 !important;
          }
        }
      }
    }
  }
  .save {
    width: 591px;
    height: 80px;
    background: #089bab;
    border-radius: 24px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    color: #ffffff;
    line-height: 80px;
    margin: 200px auto 0;
  }
}
</style>
